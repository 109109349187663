// src/App.js
import React from 'react';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import PaymentForm from './components/PaymentForm';
import PaymentReport from './components/PaymentReport';
import Dashboard from './components/Dashboard'; // Assuming you have the Dashboard component
import './App.css'; // Custom styles

function App() {
  return (
    <>
      <Navbar bg="dark" variant="dark" className="shadow">
        <Navbar.Brand href="#home">MPesa Payment System</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="#form">Payment Form</Nav.Link>
          <Nav.Link href="#report">Payment Report</Nav.Link>
        </Nav>
      </Navbar>
      <Container className="mt-4">
        <Row>
          <Col md={4}>
            <div id="form">
              <PaymentForm />
            </div>
          </Col>
          <Col md={8}>
            <Dashboard /> {/* Contains the three cards */}
          </Col>
        </Row>
        <hr />
        <div id="report">
          <PaymentReport />
        </div>
      </Container>
    </>
  );
}

export default App;
