import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Form, Row, Col, Table, Button } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './PaymentReport.css'; // Import the CSS file

const PaymentReport = () => {
  const [filterType, setFilterType] = useState('customDate');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [phone, setPhone] = useState('');
  const [reportData, setReportData] = useState([]);
  const [months, setMonths] = useState([
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setYears([currentYear, currentYear - 1, currentYear - 2]);
  }, []);

  useEffect(() => {
    fetchReportData();
  }, [filterType, startDate, endDate, month, year, phone]);

  const fetchReportData = async () => {
    const params = {
      filterType,
      startDate,
      endDate,
      month,
      year,
      phone
    };
    console.log('Fetching report data with params:', params);
    try {
      const response = await axios.get('https://ussd.clickforceitsolutions.com/api/payments/report', { params });
      console.log('Report data fetched:', response.data);
      setReportData(response.data);
    } catch (error) {
      console.error('Error fetching report data:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'filterBy':
        setFilterType(value);
        break;
      case 'startDate':
        setStartDate(value);
        break;
      case 'endDate':
        setEndDate(value);
        break;
      case 'month':
        setMonth(value);
        break;
      case 'year':
        setYear(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      default:
        break;
    }
  };

  const generatePDF = () => {
    const input = document.getElementById('report-table');
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, -heightLeft, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('payment-report.pdf');
    });
  };

  return (
    <Card className="shadow">
      <Card.Header className="card-header">
        <div className="header-title">Payment Report</div>
      </Card.Header>
      <Card.Body className="card-body">
        <Form className="mb-3">
          <Row>
            <Col md={4} sm={12}>
              <Form.Group controlId="filterBy">
                <Form.Label>Filter by:</Form.Label>
                <Form.Control
                  as="select"
                  name="filterBy"
                  value={filterType}
                  onChange={handleFilterChange}
                >
                  <option value="customDate">Custom Date Range</option>
                  <option value="month">By Month</option>
                  <option value="year">By Year</option>
                </Form.Control>
              </Form.Group>
            </Col>

            {filterType === 'customDate' && (
              <>
                <Col md={4} sm={12}>
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={handleFilterChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} sm={12}>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={handleFilterChange}
                    />
                  </Form.Group>
                </Col>
              </>
            )}

            {filterType === 'month' && (
              <Col md={4} sm={12}>
                <Form.Group controlId="month">
                  <Form.Label>Month</Form.Label>
                  <Form.Control
                    as="select"
                    name="month"
                    value={month}
                    onChange={handleFilterChange}
                  >
                    <option value="">Select Month</option>
                    {months.map((m, index) => (
                      <option key={index} value={index + 1}>{m}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}

            {filterType === 'year' && (
              <Col md={4} sm={12}>
                <Form.Group controlId="year">
                  <Form.Label>Year</Form.Label>
                  <Form.Control
                    as="select"
                    name="year"
                    value={year}
                    onChange={handleFilterChange}
                  >
                    <option value="">Select Year</option>
                    {years.map((y) => (
                      <option key={y} value={y}>{y}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}

            <Col md={4} sm={12}>
              <Form.Group controlId="phone">
                <Form.Label>Sender (Optional)</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleFilterChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="button-container">
          <Button
            onClick={generatePDF}
            variant="success"
            className="small-btn"
          >
            Print
          </Button>
        </div>
        <Table id="report-table" striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Transaction ID</th>
              <th>Transaction Ref</th>
              <th>Amount</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {reportData.map(record => (
              <tr key={record.id}>
                <td>{record.id}</td>
                <td>{new Date(record.transactionDate).toLocaleDateString()} {new Date(record.transactionDate).toLocaleTimeString()}</td>
                <td>{record.names}</td>
                <td>{record.phone}</td>
                <td>{record.transactionId}</td>
                <td>{record.transactionRef}</td>
                <td>{record.amount}</td>
                <td>{record.transactionDesc}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default PaymentReport;
