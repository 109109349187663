import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Row, Col } from 'react-bootstrap';
import io from 'socket.io-client';
import AmountWithTooltip from './AmountWithTooltip'; // Import the component

const socket = io('https://ussd.clickforceitsolutions.com/socket.io');

const Dashboard = () => {
  const [totalPayments, setTotalPayments] = useState(0);
  const [todayPayment, setTodayPayment] = useState(0);
  const [monthPayment, setMonthPayment] = useState(0);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const fetchSummary = async () => {
    try {
      const response = await axios.get('https://ussd.clickforceitsolutions.com/api/payments/summary');
      setTotalPayments(response.data.totalPayments);
      setTodayPayment(response.data.todayPayment);
      setMonthPayment(response.data.monthPayment);
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
  };

  useEffect(() => {
    fetchSummary();

    socket.on('paymentReceived', (data) => {
      setPaymentInfo({ amount: data.amount, sender: data.sender });
      fetchSummary(); // Update summary with new data
    });

    return () => {
      socket.off('paymentReceived');
    };
  }, []);

  return (
    <div>
      <Row>
        <Col md={4}>
          <Card className="shadow mb-4">
            <Card.Header>All time Payments</Card.Header>
            <Card.Body>
              <h4>Ksh {totalPayments}</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow mb-4">
            <Card.Header>Today Payments</Card.Header>
            <Card.Body>
              <h4>Ksh {todayPayment}</h4>
              {paymentInfo && (
                <AmountWithTooltip amount={paymentInfo.amount} sender={paymentInfo.sender} />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow mb-4">
            <Card.Header>Current Month</Card.Header>
            <Card.Body>
              <h4>Ksh {monthPayment}</h4>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
