import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './AmountWithTooltip.css'; // Import the CSS file

const AmountWithTooltip = ({ amount, sender }) => {
  // Ensure amount is a number
  const formattedAmount = parseFloat(amount).toFixed(2);

  const tooltip = (
    <Tooltip id="tooltip-top">
      <span className="tooltip-text">New + Ksh {formattedAmount} from {sender}</span>
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <span className="amount-text">+ Ksh {formattedAmount} from {sender}</span>
    </OverlayTrigger>
  );
};

export default AmountWithTooltip;
