import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Card, Alert, Row, Col } from 'react-bootstrap';
import './PaymentForm.css'; // Importing PaymentForm.css

const PaymentForm = () => {
  const [amount, setAmount] = useState('');
  const [sender, setSender] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
  e.preventDefault();

  const formattedDate = new Date().toISOString().slice(0, 19).replace('T', ' ');

  try {
    const paymentResponse = await axios.post('https://ussd.clickforceitsolutions.com/api/payments/receive', {
      amount,
      sender,
      date: formattedDate,
    });

    const { amount: responseAmount, date: responseDate } = paymentResponse.data;
    const successMessage = `Payment of Ksh ${responseAmount} made on ${responseDate} has been received.`;

    setSuccess(successMessage);
    setAmount('');
    setSender('');
    setError('');

    // Initiate STK Push
    try {
      const stkPushResponse = await axios.post('https://ussd.clickforceitsolutions.com/api/payments/stkpush', {
        amount: Number(amount),
        phoneNumber: sender,
      });

      console.log('STK Push Response:', stkPushResponse.data);
    } catch (stkError) {
      console.error('Error with STK Push:', stkError);
      setError('Error initiating STK Push');
    }

  } catch (err) {
    setError(err.response?.data?.error || 'There was an error!');
  }
};


  return (
    <Card className="payment-form-card shadow">
      <Card.Header className="card-header">Payment Form</Card.Header>
      <Card.Body className="card-body">
        {error && <Alert variant="danger" className="alert-danger">{error}</Alert>}
        {success && <Alert variant="success" className="alert-success">{success}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Row className="g-3">
            <Col md={6} className="form-col">
              <Form.Group controlId="formSender">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  value={sender}
                  onChange={(e) => setSender(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6} className="form-col">
              <Form.Group controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={12} className="hidden">
              <Form.Group controlId="formDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="text"
                  value={new Date().toLocaleDateString()}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={12} className="d-flex align-items-end">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default PaymentForm;
